.pds-spinner {
    position: relative;
}
.pds-spinner div {
    position: absolute;
    background: rgb(38, 103, 131);
    animation: pds-spinner 1.2s linear infinite;
}
@keyframes pds-spinner {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}
